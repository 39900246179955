import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  GettingStarted,
  CKYC,
  Login,
  PersonalData,
  DocumentsUpload,
  Mandate,
  MyLoans,
  HelpSupport,
  AllDocuments,
} from "./pages";
import DesktopErrorPage from "./pages/DesktopErrorPage";
import DigioMandate from "./pages/DigioMandate";
import EditProfile from "./pages/EditProfile";
import EMandate from "./pages/Mandate/EMandate";
import LoanAgreement from "./pages/Mandate/LoanAgreement";
import RazorpayMandate from "./pages/RazorpayMandate";
import ThankYou from "./pages/ThankYou";
import MobileOnly from "./utils/MobileOnlyRoute";
import PrivateRoute from "./utils/PrivateRoute";
import AccountAggregator from "./pages/AccountAggregator";
import ReversePennydrop from "./pages/ReversePennydrop";

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:id" element={<GettingStarted />} />
        <Route
          path="/login"
          element={
            <MobileOnly>
              <Login />
            </MobileOnly>
          }
        />
        <Route
          path="/ckyc"
          element={
            <MobileOnly>
              <CKYC />
            </MobileOnly>
          }
        />
        <Route
          path="/personal-data"
          element={
            <MobileOnly>
              <PersonalData />
            </MobileOnly>
          }
        />
        <Route
          path="/documents"
          element={
            <MobileOnly>
              <PrivateRoute>
                <DocumentsUpload />
              </PrivateRoute>
            </MobileOnly>
          }
        />
        <Route
          path="/mandate"
          element={
            <MobileOnly>
              <PrivateRoute>
                <Mandate />
              </PrivateRoute>
            </MobileOnly>
          }
        />
        <Route
          path="/e-mandate"
          element={
            <MobileOnly>
              <EMandate />
            </MobileOnly>
          }
        />
        <Route
          path="/loan-agreement"
          element={
            <MobileOnly>
              <PrivateRoute>
                <LoanAgreement />
              </PrivateRoute>
            </MobileOnly>
          }
        />
        <Route
          path="/my-loans"
          element={
            <MobileOnly>
              <MyLoans />
            </MobileOnly>
          }
        />
        <Route
          path="/help-support"
          element={
            <MobileOnly>
              <HelpSupport />
            </MobileOnly>
          }
        />
        <Route
          path="/all-documents"
          element={
            <MobileOnly>
              <AllDocuments />
            </MobileOnly>
          }
        />
        <Route
          path="/edit-profile"
          element={
            <MobileOnly>
              <EditProfile />
            </MobileOnly>
          }
        />
        <Route
          path="/digio-mandate"
          element={
            <MobileOnly>
              <PrivateRoute>
                <DigioMandate />
              </PrivateRoute>
            </MobileOnly>
          }
        />
        <Route
          path="/razorpay-mandate"
          element={
            <MobileOnly>
              <PrivateRoute>
                <RazorpayMandate />
              </PrivateRoute>
            </MobileOnly>
          }
        />
        <Route
          path="/thank-you"
          element={
            <MobileOnly>
              <PrivateRoute>
                <ThankYou />
              </PrivateRoute>
            </MobileOnly>
          }
        />
        <Route
          path="/account-aggregator"
          element={
            <MobileOnly>
              <PrivateRoute>
                <AccountAggregator />
              </PrivateRoute>
            </MobileOnly>
          }
        />
        <Route
          path="/reverse-penny-drop"
          element={
            <MobileOnly>
              <PrivateRoute>
                <ReversePennydrop />
              </PrivateRoute>
            </MobileOnly>
          }
        />
        <Route path="/desktop-only" element={<DesktopErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
