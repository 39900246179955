import React from "react";
import styles from "./index.module.css";
import DragAndDropCard from "../../components/atoms/DragAndDrop";
import ListDocumentCard from "../../components/molecules/ListDocumentCard";
// import { AuthContext } from "../../context/AuthContext";
import SideBar from "../../components/molecules/SideBar";

function AllDocuments() {
  // const { loggedInUserData } = useContext(AuthContext);

  return (
    <div className={styles.main}>
      <SideBar />
      <div className={styles.body}>
        <div className={styles.container}>
          <h1>Upload Your Document</h1>
          <DragAndDropCard />
          <ListDocumentCard />
          <ListDocumentCard />
          <ListDocumentCard />
        </div>
      </div>
    </div>
  );
}

export default AllDocuments;
