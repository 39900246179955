import * as React from "react";
import styles from "./index.module.css";
import edit_icon from "../../../images/editProfile/edit.svg";

interface TitleProps {
  title?: string;
  icon?: any;
}

function Title({ title, icon }: TitleProps) {
  return (
    <div className={styles.title}>
      <span>
        <div className={styles.imageBackground}>
          <img src={icon} alt="" />
        </div>
        <h2>{title}</h2>
      </span>

      <div className={styles.editButton}>
        <img src={edit_icon} alt="" />
        Edit
      </div>
    </div>
  );
}

export default Title;
