import React from "react";
import Styles from "./index.module.css";
import Cross from "../../icons/close.svg";
import XMark from "../../icons/x-mark.svg";

interface ErrorDialogProps {
  error: string;
  onClose: () => void;
}

function ErrorDialog({ error, onClose }: ErrorDialogProps) {
  return (
    <div className={Styles.backdrop}>
      <div className={Styles.errorContainer}>
        <div className={Styles.close} onClick={onClose}>
          <img src={XMark} alt="close" />
        </div>
        <h2>Error</h2>
        <h3>{error}</h3>
      </div>
    </div>
  );
}

export default ErrorDialog;
