import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

interface MobileOnlyProps {
  children: React.ReactNode;
}

function MobileOnly({ children }: MobileOnlyProps) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isMobile) {
      navigate("/desktop-only");
    }
  }, [navigate]);

  return <>{children}</>;
}

export default MobileOnly;
