// import React from "react";
// import Button from "../../components/atoms/Button";

// function ThankYou() {
//   return (
//     <div
//       style={{
//         display: "flex",
//         flex: 1,
//         flexDirection: "column",
//         alignItems: "center",
//         justifyContent: "flex-end",
//         backgroundColor: "#F28F3B",
//         height: "100vh",
//       }}
//     >
//       <div
//         style={{
//           backgroundColor: "#FFFFFF",
//           // borderTopEndRadius: 25,
//           // borderTopStartRadius: 25,
//           borderRadius: 25,
//           padding: 25,
//           width: "100%",
//           height: "85%",
//         }}
//       >
//         <div
//           style={{
//             marginTop: 20,
//           }}
//         >
//           {/* <HoldOn /> */}
//         </div>
//         <div style={{ flex: 1, justifyContent: "center" }}>
//           <div
//             style={{
//               alignItems: "center",
//               backgroundColor: "#FFF7F1",
//               padding: 15,
//               borderRadius: 10,
//             }}
//           >
//             <p
//               style={{
//                 textAlign: "center",
//                 fontWeight: "bold",
//                 fontSize: 24,
//                 marginBottom: 5,
//                 color: "#F28F3B",
//               }}
//             >
//               Hold On!
//             </p>
//             <p
//               style={{
//                 textAlign: "center",
//                 fontSize: 16,
//                 color: "#253348",
//               }}
//             >
//               Our application is under rediv we will update soon
//             </p>
//           </div>
//         </div>
//         <div
//           style={{
//             alignItems: "center",
//             marginBottom: 20,
//           }}
//         >
//           <p
//             style={{
//               color: "#B4BFD5",
//               fontSize: 16,
//             }}
//           >
//             If you have a problem, please contact us:
//           </p>
//           <p
//             style={{
//               color: "#F28F3B",
//               fontSize: 16,
//             }}
//           >
//             88888-56545
//           </p>
//         </div>
//         <Button
//           text="GO TO HOME"
//           onPress={() => {}}
//           // imageRight={require("../../../assets/icons/arrow-right.png")}
//         />
//       </div>
//     </div>
//   );
// }

// export default ThankYou;

import React from "react";
import styles from "./styles.module.css";
import HoldOn from "../../images/hold-on.svg";

function ThankYou() {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        {/* <h1>Mandate</h1> */}
        <img src="/feemonklogo.png" alt="" className={styles.image} />
        <img src={HoldOn} alt="" className={styles.heroImage} />
        <div className={styles.holdOnTextContainer}>
          <p className={styles.holdOnHeaderText}>Thank you!</p>
          <p>Your application is under review. We will update soon</p>
        </div>

        <div>
          <p className={styles.holdOnHelperText}>
            If you have a problem, please contact us:
          </p>
          <p className={styles.holdOnHelperTextSmall}>hello@feemonk.com</p>
        </div>
      </div>
    </div>
  );
}

export default ThankYou;
