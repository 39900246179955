import React from "react";
import styles from "./index.module.css";

function UserCard() {
  return (
    <div className={styles.container}>
      <div>
        <img
          src="https://images.pexels.com/photos/301958/pexels-photo-301958.jpeg?auto=compress&cs=tinysrgb&w=600"
          alt=""
        />
      </div>
      <div>
        <p>William</p>
        <p>aa@bb.com</p>
      </div>
    </div>
  );
}

export default UserCard;
