import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import style from "./index.module.css";

function DragAndDrop() {
  const onDrop = useCallback((acceptedFiles: any) => {
    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} className={style.dropzone}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <div>
          <img src="./ADD.png" alt="" />
          <p className={style.textLarge}>Drag and Drop</p>
          <p>or Browse</p>
        </div>
      )}
    </div>
  );
}

export default DragAndDrop;
