import React from "react";
import styles from "./styles.module.css";
import ImageIcons from "../../images/icons-back.png";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { API_URL_STAGING } from "../../utils/key";

function GettingStarted() {
  const user = useParams();
  console.log(user, "user is");
  var requestOptions: RequestInit = {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify({user: user.id})
  };

  fetch(`${API_URL_STAGING}/application/send-tnc-mail`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      console.log(result);
    })
    .catch((error) => console.log("error", error));

  return (
    <div className={styles.main}>
      <img src="/feemonklogo.png" alt="" className={styles.image} />
      <p className={styles.text}>
        Pay your Education Fees in Monthly Installments
      </p>
      <Link to={"/login"}>
        <button className={styles.button}>
          <img src={ImageIcons} alt="" />
          <span>GET STARTED</span>
        </button>
      </Link>
    </div>
  );
}

export default GettingStarted;
