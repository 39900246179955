import React from "react";
import SideBar from "../../components/molecules/SideBar";
import Grid from "./components/Grid";
import Title from "./components/Title";
import styles from "./index.module.css";
import location_icon from "../../images/editProfile/location.svg";
import user_icon from "../../images/editProfile/user.svg";
import security_safe_icon from "../../images/editProfile/security-safe.svg";
import shield_tick_icon from "../../images/editProfile/shield-tick.svg";

function EditProfile() {
  return (
    <main className={styles.main}>
      <SideBar />
      <div className={styles.body}>
        <div className={styles.profileImageContainer}>
          <img
            src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="Profile"
          />
        </div>
        <Title icon={location_icon} title="Address" />
        <Grid>
          <div>
            <label className={styles.label}>Current Address</label>
            <p className={styles.text}>
              251, Pilgrim Avenue, Chavy Chase, MD-751021
            </p>
          </div>

          <div>
            <label className={styles.label}>Permanent Address</label>
            <p className={styles.text}>
              251, Pilgrim Avenue, Chavy Chase, MD-751021
            </p>
          </div>
        </Grid>

        <Title icon={user_icon} title="Personal Details" />

        <Grid>
          <div>
            <label className={styles.label}>Name</label>
            <p className={styles.text}>Sara Williamson</p>
          </div>

          <div>
            <label className={styles.label}>Date Of Birth</label>
            <p className={styles.text}>08/05/1999</p>
          </div>

          <div>
            <label className={styles.label}>Mobile Number</label>
            <p className={styles.text}>+365 6359 5698</p>
          </div>

          <div>
            <label className={styles.label}>Enter E-mail Address</label>
            <p className={styles.text}>helloahnk26@gmail.com</p>
          </div>
        </Grid>

        <Title icon={security_safe_icon} title="Bank Accounts" />

        <Grid>
          <div>
            <label className={styles.label}>Bank Holder Name</label>
            <p className={styles.text}>Sara Williamson</p>
          </div>

          <div>
            <label className={styles.label}>IFSC Code</label>
            <p className={styles.text}>MKHS00065F</p>
          </div>

          <div>
            <label className={styles.label}>Account Number</label>
            <p className={styles.text}>36563595698</p>
          </div>

          <div>
            <label className={styles.label}>Enter E-mail Address</label>
            <p className={styles.text}>helloahnk26@gmail.com</p>
          </div>
        </Grid>

        <Title icon={shield_tick_icon} title="KYC" />
        <Grid>
          <div>
            <label className={styles.label}>PAN Card</label>
            <p className={styles.text}>ADXPN7259C</p>
          </div>

          <div>
            <label className={styles.label}>Aadhaar Card Number</label>
            <p className={styles.text}>5485 6774 6338</p>
          </div>
        </Grid>
      </div>
    </main>
  );
}

export default EditProfile;
