import React from "react";
import styles from "./index.module.css";

interface LoanCardProps {
  data: {
    title: string;
    instituteName: string;
    loanAmount: string;
    loanInterest: string;
    loanApplicationDate: string;
    status?: number;
  };
}

const colors: any = {
  1: "#f28f3b",
  2: "#ef5350",
  3: "#398bf7",
  4: "#39d5cf",
  5: "#BCBCBC",
};

function LoanCard(props: LoanCardProps) {
  return (
    <div className={styles.card}>
      <div
        className={styles.heading}
        style={{
          background: colors[props.data.status || 5],
        }}
      >
        <p className={styles.headingTitle}>{props.data.title}</p>
      </div>
      <div className={styles.grid}>
        <div className={styles.desc}>
          <p className={styles.descHead}>{props.data.instituteName}</p>
          <p className={styles.descBody}>Institute Name</p>
        </div>
        <div className={styles.desc}>
          <p className={styles.descHead}>{props.data.loanApplicationDate}</p>
          <p className={styles.descBody}>Loan Applied on</p>
        </div>
        <div className={styles.desc}>
          <p className={styles.descHead}>{props.data.loanAmount}</p>
          <p className={styles.descBody}>Amount</p>
        </div>
        <div className={styles.desc}>
          <p className={styles.descHead}>{props.data.loanInterest}</p>
          <p className={styles.descBody}>Intrest</p>
        </div>
      </div>
      <button
        className={styles.detailsButton}
        style={{
          background: colors[props.data.status || 5],
        }}
      >
        View Details
      </button>
    </div>
  );
}

export default LoanCard;
