export const storeData = async (name: string, value: string) => {
  try {
    await sessionStorage.setItem(name, value);
  } catch (err) {
    console.log(err);
  }
};

export const getData = async (item: string) => {
  try {
    const res = await sessionStorage.getItem(item);
    console.log(res);

    return JSON.parse(res || "{}");
  } catch (err) {
    console.log(err);
  }
};
