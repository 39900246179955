import React from "react";

function DesktopErrorPage() {
  return (
    <div>
      <h1
        style={{
          textAlign: "center",
        }}
      >
        Please Open This in Mobile Browser, Desktop Browser Doesn’t Support This
        Application
      </h1>
    </div>
  );
}

export default DesktopErrorPage;
