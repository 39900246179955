import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserInitialState {
  userData: {
    ckycData: {
      req_id?: number;
      success?: boolean;
      error_message?: string;
      ckyc_remarks?: string;
      result?: {
        PERSONAL_DETAILS?: any;
        IDENTITY_DETAILS?: any;
        RELATED_PERSON_DETAILS?: any;
        IMAGE_DETAILS?: any;
      };
    };
    authData: {
      userId: string;
      mobile: string;
      status: string;
    };
    panData: {
      panNumber?: string;
      dob?: string;
      nameOnPan?: string;
    };
  };
}

const initialState: UserInitialState = {
  userData: {
    ckycData: {},
    authData: {
      userId: "",
      mobile: "",
      status: "",
    },
    panData: {
      panNumber: "string",
      dob: "string",
      nameOnPan: "string",
    },
  },
};

export const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    setCkycData: (state: UserInitialState, action: PayloadAction<any>) => {
      state.userData.ckycData = action.payload;
    },
    setAuthData: (state: UserInitialState, action: PayloadAction<any>) => {
      state.userData.authData = action.payload;
    },
    setPanData: (state: UserInitialState, action: PayloadAction<any>) => {
      // console.log(action.payload);
      // console.log(state);

      state.userData.panData = action.payload;
    },
  },
});

export const { setCkycData, setAuthData, setPanData } = userDataSlice.actions;

export default userDataSlice.reducer;
