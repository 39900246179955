import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../components/atoms/Button";
import InputText from "../../components/atoms/InputText";
import Label from "../../components/atoms/Label";
// import { data } from "../../helper/ckyc_sample_data";
import {
  setCkycData,
  setPanData,
  UserInitialState,
} from "../../redux/slice/userData";
import styles from "./styles.module.css";

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const mob = useSelector(
    (state: { userData: UserInitialState }) =>
      state?.userData?.userData?.authData?.mobile
  );
  const [pan, setPan] = useState("");
  const [dob, setDob] = useState("");

  const navigate = useNavigate();

  const getCkycData = async (
    panNum: string,
    dobNum: string,
    mobNum: string
  ) => {
    // FIXME: API call to get ckyc data (Used static data to use less resources)
    try {
      const response = await fetch("https://ping.arya.ai/api/v1/ckyc-fm", {
        method: "POST",
        headers: {
          token: "9d21fc9da5616f93f42cb6b61c87fb1f",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          pan: panNum,
          dob: dobNum,
          req_id: mobNum,
        }),
      });

      const result = await response.json();

      // const result = data;
      dispatch(setCkycData(result));
      navigate("/personal-data");
    } catch (error) {
      console.log("Error:", error);
      await getPanData(panNum, dobNum);
    }
  };

  const getPanData = async (panNum: string, dobNum: string) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        panNumber: panNum,
      });

      const response = await fetch(
        "https://staging-backend.feemonk.com/pan/verify",
        {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        }
      );

      const result = await response.json();

      dispatch(
        setPanData({
          panNumber: result.data.panNumber,
          dob: dobNum,
          nameOnPan: result.data.nameOnPan,
        })
      );
      navigate("/personal-data");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <h1>KYC Details</h1>
        <div className={styles.inputField}>
          <Label text="Pan Card Number" />
          <InputText
            placeholder="Pan Card Number"
            type="text"
            value={pan}
            changeHandler={(e) => setPan(e.target.value)}
          />
        </div>
        <Label text="Date of Birth (DD-MM-YYYY)" />
        <div className={styles.inputField}>
          <InputText
            placeholder="DD-MM-YYYY"
            type="date"
            value={dob}
            changeHandler={(e) => setDob(e.target.value)}
          />
        </div>
        <Button
          onPress={() => {
            getCkycData(pan, dob, mob);
          }}
          text="SUBMIT"
        />
      </div>
    </div>
  );
};

export default Home;
