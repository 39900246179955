import React from "react";
import LoanCard from "../../components/molecules/LoanCard";
import SideBar from "../../components/molecules/SideBar";
import styles from "./index.module.css";

function MyLoans() {
  return (
    <main className={styles.main}>
      <SideBar />
      <div className={styles.body}>
        <h3 className={styles.rowTitle}>Active Loans</h3>
        <div className={styles.cardsRow}>
          <LoanCard
            data={{
              title: "Personal Loan",
              instituteName: "Bank of Baroda",
              loanAmount: "46,000",
              loanInterest: "2.5%",
              loanApplicationDate: "07/07/2020",
              status: 1,
            }}
          />
          <LoanCard
            data={{
              title: "Personal Loan",
              instituteName: "Bank of Baroda",
              loanAmount: "46,000",
              loanInterest: "2.5%",
              loanApplicationDate: "07/07/2020",
              status: 2,
            }}
          />
          <LoanCard
            data={{
              title: "Personal Loan",
              instituteName: "Bank of Baroda",
              loanAmount: "46,000",
              loanInterest: "2.5%",
              loanApplicationDate: "07/07/2020",
              status: 3,
            }}
          />
          <LoanCard
            data={{
              title: "Personal Loan",
              instituteName: "Bank of Baroda",
              loanAmount: "46,000",
              loanInterest: "2.5%",
              loanApplicationDate: "07/07/2020",
              status: 4,
            }}
          />
        </div>
        <h3 className={styles.rowTitle}>Past Loans</h3>
        <div className={styles.cardsRow}>
          <LoanCard
            data={{
              title: "Personal Loan",
              instituteName: "Bank of Baroda",
              loanAmount: "46,000",
              loanInterest: "2.5%",
              loanApplicationDate: "07/07/2020",
              status: 5,
            }}
          />
          <LoanCard
            data={{
              title: "Personal Loan",
              instituteName: "Bank of Baroda",
              loanAmount: "46,000",
              loanInterest: "2.5%",
              loanApplicationDate: "07/07/2020",
              status: 5,
            }}
          />
          <LoanCard
            data={{
              title: "Personal Loan",
              instituteName: "Bank of Baroda",
              loanAmount: "46,000",
              loanInterest: "2.5%",
              loanApplicationDate: "07/07/2020",
              status: 5,
            }}
          />
          <LoanCard
            data={{
              title: "Personal Loan",
              instituteName: "Bank of Baroda",
              loanAmount: "46,000",
              loanInterest: "2.5%",
              loanApplicationDate: "07/07/2020",
              status: 5,
            }}
          />
        </div>
      </div>
    </main>
  );
}

export default MyLoans;
