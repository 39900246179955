import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SidebarIcons from "../../../images/SidebarIcons";
import UserCard from "../../atoms/UserCard";
import styles from "./index.module.css";

function SideBar() {
  const { pathname } = useLocation();

  return (
    <div className={styles.container}>
      <img src="/feemonklogo.png" alt="" className={styles.image} />
      <div className={styles.line}></div>
      <UserCard />
      <Link to="/" style={{ textDecoration: "none" }}>
        <div
          className={pathname === "/" ? styles.activeLink : styles.passiveLink}
        >
          <SidebarIcons type="HOME" active={pathname === "/"} />
          <p>Home</p>
        </div>
      </Link>
      <Link to="/my-loans" style={{ textDecoration: "none" }}>
        <div
          className={
            pathname === "/my-loans" ? styles.activeLink : styles.passiveLink
          }
        >
          <SidebarIcons type="LOANS" active={pathname === "/my-loans"} />
          <p>My Loans</p>
        </div>
      </Link>
      <Link to="/help-support" style={{ textDecoration: "none" }}>
        <div
          className={
            pathname === "/help-support"
              ? styles.activeLink
              : styles.passiveLink
          }
        >
          <SidebarIcons type="HELP" active={pathname === "/help-support"} />
          <p>Help & Support</p>
        </div>
      </Link>
      <Link to="/all-documents" style={{ textDecoration: "none" }}>
        <div
          className={
            pathname === "/all-documents"
              ? styles.activeLink
              : styles.passiveLink
          }
        >
          <SidebarIcons
            type="DOCUMENTS"
            active={pathname === "/all-documents"}
          />
          <p>Documents</p>
        </div>
      </Link>
    </div>
  );
}

export default SideBar;
