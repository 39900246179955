import React from "react";
import { Provider } from "react-redux";
import { AuthProvider } from "./context/AuthContext";
import { store } from "./redux/store";
import Router from "./Router";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Provider store={store}>
        <Router />
      </Provider>
    </AuthProvider>
  );
};

export default App;
