import React, { useState } from "react";
import styles from "./index.module.css";
import Button from "../../components/atoms/Button";
import InputText from "../../components/atoms/InputText";
import Label from "../../components/atoms/Label";
import SideBar from "../../components/molecules/SideBar";

function HelpAndSupport() {
  const [value, setValue] = useState({
    issueType: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (key: string, val: string) => {
    setValue({ ...value, [key]: val });
  };
  const { issueType, email, phone, message } = value;

  return (
    <div className={styles.main}>
      <SideBar />
      <div className={styles.body}>
        <div className={styles.container}>
          <h1>Help & Support</h1>
          <p>
            Leave us a message, we will get contact with you as soon as
            possible.
          </p>
          <Label text="Issue Type" />
          <div className={styles.inputField}>
            <InputText
              placeholder="Issues Type"
              type="option"
              options={[
                {
                  placeholder: "Select Your Issue",
                  value: "",
                },
                {
                  placeholder: "Payments",
                  value: "payments",
                },
                {
                  placeholder: "Profile",
                  value: "profile",
                },
                {
                  placeholder: "Login",
                  value: "login",
                },
                {
                  placeholder: "Other",
                  value: "other",
                },
              ]}
              value={issueType}
              changeHandler={(e) => handleChange("issueType", e.target.value)}
            />
          </div>
          <Label text="Enter Email" />
          <div className={styles.inputField}>
            <InputText
              placeholder="Enter Email"
              type="email"
              value={email}
              changeHandler={(e) => handleChange("email", e.target.value)}
            />
          </div>
          <Label text="Enter Mobile Number" />
          <div className={styles.inputField}>
            <InputText
              placeholder="Phone Number"
              type="tel"
              value={phone}
              changeHandler={(e) => handleChange("phone", e.target.value)}
            />
          </div>
          <Label text="Your Message" />
          <div className={styles.inputField}>
            <InputText
              placeholder="What Do You Want To Tell Us About?"
              type="textarea"
              value={message}
              changeHandler={(e) => handleChange("message", e.target.value)}
            />
          </div>
          <Button
            onPress={() => {
              //   sendOtp();
            }}
            text="SEND"
            //   imageLeft={RightArrow}
          />
        </div>
      </div>
    </div>
  );
}

export default HelpAndSupport;
