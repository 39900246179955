import React from "react";
import help_inactive from "../images/sidebar/help_inactive.svg";
import docs_inactive from "../images/sidebar/docs_inactive.svg";
import loan_inactive from "../images/sidebar/loan_inactive.svg";
import home_inactive from "../images/sidebar/home_inactive.svg";

import help_active from "../images/sidebar/help_active.svg";
import loan_active from "../images/sidebar/loan_active.svg";
import docs_active from "../images/sidebar/docs_active.svg";

interface SidebarIconProps {
  type: "HOME" | "LOANS" | "HELP" | "DOCUMENTS";
  active?: boolean;
}

function SidebarIcons({ type, active }: SidebarIconProps) {
  if (active) {
    switch (type) {
      case "HOME":
        return <img src={home_inactive} alt="" />;
      case "LOANS":
        return <img src={loan_active} alt="" />;
      case "HELP":
        return <img src={help_active} alt="" />;
      case "DOCUMENTS":
        return <img src={docs_active} alt="" />;
      default:
        break;
    }
  }
  switch (type) {
    case "HOME":
      return <img src={home_inactive} alt="" />;
    case "LOANS":
      return <img src={loan_inactive} alt="" />;
    case "HELP":
      return <img src={help_inactive} alt="" />;
    case "DOCUMENTS":
      return <img src={docs_inactive} alt="" />;
    default:
      break;
  }
  return <div>SidebarIcons</div>;
}

export default SidebarIcons;
