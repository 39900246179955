import React from "react";
import styles from "./index.module.css";

function ListDocumentCard() {
  return (
    <div className={styles.container}>
      <span>
        <p>
          <img src="./PNG.png" alt="" />
        </p>
        <p>Passbook</p>
      </span>
      <p>512kb</p>
      <p>.png</p>
      <img src="./EYE.png" alt="" />
    </div>
  );
}

export default ListDocumentCard;
