import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../components/atoms/Button";
import InputText from "../../components/atoms/InputText";
import Label from "../../components/atoms/Label";
import { UserInitialState } from "../../redux/slice/userData";
import styles from "./styles.module.css";

const PersonalData: React.FC = () => {
  // const dispatch = useDispatch();
  // const mob = useSelector(
  //   (state: { userData: UserInitialState }) =>
  //     state?.userData?.userData?.authData?.mobile
  // );

  const navigate = useNavigate();

  const [radio, setRadio] = useState("STUDENT");

  const ckycData = useSelector(
    (state: { userData: UserInitialState }) =>
      state?.userData?.userData?.ckycData
  );

  const panData = useSelector(
    (state: { userData: UserInitialState }) =>
      state?.userData?.userData?.panData
  );

  const getFullName = (PERSONAL_DETAILS: any) => {
    let fullName = `${PERSONAL_DETAILS?.FNAME || ""}${
      PERSONAL_DETAILS?.MNAME || " "
    }${PERSONAL_DETAILS?.LNAME || ""}`;

    return fullName.trim();
  };

  const [value, setValue] = useState({
    name:
      `${getFullName(ckycData?.result?.PERSONAL_DETAILS)}` ||
      panData?.nameOnPan ||
      "",
    dob: ckycData?.result?.PERSONAL_DETAILS?.DOB || panData?.dob || "",
    pan: ckycData?.result?.PERSONAL_DETAILS?.PAN || panData?.panNumber || "",
    zip: ckycData?.result?.PERSONAL_DETAILS?.PERM_PIN || "",
    income: "",
    instituteName: "",
    programName: "",
    courseFees: "",
    studentName: "",
    relationship: "",
  });

  const {
    name,
    dob,
    pan,
    instituteName,
    programName,
    courseFees,
    studentName,
    relationship,
    zip,
  } = value;

  const handleChange = (key: string, val: string) => {
    setValue({ ...value, [key]: val });
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <h1>Personal Data</h1>
        <p>This data will be saved for your future applications.</p>
        <div className={styles.inputField}>
          <Label text="Enter Your Name" />
          <InputText
            placeholder="Enter Your Name"
            type="text"
            value={name}
            changeHandler={(e) => handleChange("name", e.target.value)}
          />
        </div>
        <div className={styles.inputField}>
          <Label text="Date of Birth" />
          <InputText
            placeholder="DD-MM-YYYY "
            type="date"
            value={zip ? dob.split("-").reverse().join("-") : dob}
            changeHandler={(e) => handleChange("dob", e.target.value)}
          />
        </div>
        <div className={styles.inputField}>
          <Label text="Pan Card Number" />
          <InputText
            placeholder="Pan Card Number"
            type="text"
            value={pan}
            changeHandler={(e) => handleChange("pan", e.target.value)}
          />
        </div>
        <div
          onChange={(event) =>
            setRadio((event.target as HTMLInputElement).value)
          }
          defaultValue="STUDENT"
        >
          <input
            type="radio"
            value="STUDENT"
            name="student"
            checked={radio === "STUDENT"}
          />{" "}
          I am the Student
          <input
            type="radio"
            value="NOT_STUDENT"
            name="student"
            checked={radio === "NOT_STUDENT"}
          />{" "}
          I am not the Student
        </div>
        {radio === "STUDENT" ? (
          <>
            <div className={styles.inputField}>
              <Label text="Institute Name" />
              <InputText
                placeholder="Institute Name"
                type="text"
                value={instituteName}
                changeHandler={(e) =>
                  handleChange("instituteName", e.target.value)
                }
              />
            </div>
            <div className={styles.inputField}>
              <Label text="Program Name" />
              <InputText
                placeholder="Program Name"
                type="text"
                value={programName}
                changeHandler={(e) =>
                  handleChange("programName", e.target.value)
                }
              />
            </div>
            <div className={styles.inputField}>
              <Label text="Course Fees" />
              <InputText
                placeholder="Course Fees"
                type="text"
                value={courseFees}
                changeHandler={(e) =>
                  handleChange("courseFees", e.target.value)
                }
              />
            </div>
          </>
        ) : (
          <>
            <div className={styles.inputField}>
              <Label text="Student Name" />
              <InputText
                placeholder="Student Name"
                type="text"
                value={studentName}
                changeHandler={(e) =>
                  handleChange("studentName", e.target.value)
                }
              />
            </div>
            <div className={styles.inputField}>
              <Label text="Relationship" />
              <InputText
                placeholder="Relationship"
                type="text"
                value={relationship}
                changeHandler={(e) =>
                  handleChange("relationship", e.target.value)
                }
              />
            </div>
            <div className={styles.inputField}>
              <Label text="Institute Name" />
              <InputText
                placeholder="Institute Name"
                type="text"
                value={instituteName}
                changeHandler={(e) =>
                  handleChange("instituteName", e.target.value)
                }
              />
            </div>
            <div className={styles.inputField}>
              <Label text="Program Name" />
              <InputText
                placeholder="Program Name"
                type="text"
                value={programName}
                changeHandler={(e) =>
                  handleChange("programName", e.target.value)
                }
              />
            </div>
            <div className={styles.inputField}>
              <Label text="Course Fees" />
              <InputText
                placeholder="Course Fees"
                type="text"
                value={courseFees}
                changeHandler={(e) =>
                  handleChange("courseFees", e.target.value)
                }
              />
            </div>
          </>
        )}
        <Button
          onPress={() => {
            navigate("/documents");
          }}
          text="SUBMIT"
        />
      </div>
    </div>
  );
};

export default PersonalData;
